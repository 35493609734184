import React from 'react';
import type { FormErrors } from '@jwp/ott-common/types/form';
import CheckCircle from '@jwp/ott-theme/assets/icons/check.svg?react';
import Warning from '@jwp/ott-theme/assets/icons/warning.svg?react';
import { useTranslation } from 'react-i18next';

import FormFeedback from '../FormFeedback/FormFeedback';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';
import Button from '../Button/Button';
import TextField from '../form-fields/TextField/TextField';
import Icon from '../Icon/Icon';

import styles from './DeviceFlowVerification.module.scss';

export type DeviceFlowLoginData = {
  code: string;
};

// VD Private
type Props = {
  code: string | undefined;
  hasAccess: boolean;
  deviceName: string | undefined;
  userName: string | undefined;
  authorizationError?: unknown;
  onClose: () => void;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  errors: FormErrors<DeviceFlowLoginData>;
  values: DeviceFlowLoginData;
  validationError?: boolean;
  submitting: boolean;
};

const DeviceFlowVerification = ({
  code,
  hasAccess,
  deviceName,
  userName,
  authorizationError,
  onClose,
  onSubmit,
  onChange,
  values,
  errors,
  validationError,
  submitting,
}: Props) => {
  const { t } = useTranslation('account');

  if (authorizationError) {
    return (
      <div className={styles.centered}>
        <Icon icon={Warning} className={styles.warningIcon} />
        <h2 className={styles.titleLarge}>{t('login_device.error_title')}</h2>
        <p>{t('login_device.error_description')}</p>
        <Button type="button" label={t('login_device.close')} variant="contained" color="primary" size="large" fullWidth onClick={onClose} />
      </div>
    );
  }

  return (
    <form onSubmit={onSubmit} className={styles.main} noValidate>
      {errors.form ? (
        <FormFeedback variant="error" visible={!validationError}>
          {errors.form}
        </FormFeedback>
      ) : null}
      {authorizationError ? (
        <div className={styles.centered}>
          <Icon icon={Warning} className={styles.warningIcon} />
          <h2 className={styles.titleLarge}>{t('login_device.error_title')}</h2>
          <p>{t('login_device.error_description')}</p>
          <Button type="button" label={t('login_device.close')} variant="contained" color="primary" size="large" fullWidth onClick={onClose} />
        </div>
      ) : hasAccess ? (
        <div className={styles.centered}>
          <Icon icon={CheckCircle} className={styles.checkIcon} />
          <h2 className={styles.titleLarge}>{t('login_device.success_title')}</h2>
          <Button type="button" label={t('login_device.close')} variant="contained" color="primary" size="large" fullWidth onClick={onClose} />
        </div>
      ) : code ? (
        <>
          <h2 className={styles.title}>{t('login_device.login_request_title')}</h2>
          <p>{t('login_device.login_request_description', { deviceName: deviceName ? `(${deviceName})` : '' })}</p>
          {!!userName && (
            <p className={styles.statement}>
              <b>{t('login_device.logged_in_as')}</b>
              <br />
              {userName}
            </p>
          )}
          <p className={styles.statement}>
            <b>{t('login_device.see_code')}</b>
            <br />
            {code}
          </p>
          <div className={styles.buttons}>
            <Button type="submit" label={t('login_device.submit')} variant="contained" color="primary" size="large" disabled={submitting} fullWidth />
            <Button type="button" label={t('login_device.cancel')} variant="contained" size="large" disabled={submitting} onClick={onClose} fullWidth />
          </div>
        </>
      ) : (
        <>
          <h2 className={styles.title}>{t('login_device.enter_code')}</h2>
          <TextField
            value={values.code}
            onChange={onChange}
            label={t('login_device.code')}
            error={!!errors.code}
            helperText={errors.code}
            name="code"
            type="code"
            required
          />
          {submitting && <LoadingOverlay transparentBackground inline />}
          <Button type="submit" label={t('login_device.confirm')} variant="contained" color="primary" size="large" disabled={submitting} fullWidth />
        </>
      )}
    </form>
  );
};

export default DeviceFlowVerification;
