import AccountController from '@jwp/ott-common/src/controllers/AccountController';
import { getModule } from '@jwp/ott-common/src/modules/container';
import { useMutation } from 'react-query';

const useDeviceFlowVerification = () => {
  const accountController = getModule(AccountController);

  return useMutation({
    mutationKey: ['deviceFlow', 'authorizeCode'],
    mutationFn: accountController.authorizeCode,
  });
};

export default useDeviceFlowVerification;
