import type { PlaylistItem } from '@jwp/ott-common/types/playlist';
import { useConfigStore } from '@jwp/ott-common/src/stores/ConfigStore';
import { useAccountStore } from '@jwp/ott-common/src/stores/AccountStore';
import env from '@jwp/ott-common/src/env';
import type { Jwpltx } from '@jwp/ott-common/types/jwpltx';

import useEventCallback from './useEventCallback';

const useOttAnalytics = (item?: PlaylistItem, feedId: string = '') => {
  const analyticsToken = useConfigStore((s) => s.config.analyticsToken);
  const user = useAccountStore((state) => state.user);
  const { config } = useConfigStore((s) => s);

  // ott app user id (oaid)
  const oaid: number | undefined = user?.id ? Number(user.id) : undefined;
  // app config id (oiid)
  const oiid = config?.id;
  // app version number (av)
  const av = env.APP_VERSION;

  const jwpltx = 'jwpltx' in window ? (window.jwpltx as Jwpltx) : undefined;

  const readyHandler = useEventCallback(() => {
    if (!analyticsToken || !item) return;

    jwpltx?.ready(analyticsToken, window.location.hostname, feedId, item.mediaid, item.title, oaid, oiid, av);
  });

  const timeHandler = useEventCallback(({ position, duration }: { position: number; duration: number }) => {
    jwpltx?.time(position, duration);
  });

  const seekHandler = useEventCallback(({ offset, duration }: { offset: number; duration: number }) => {
    jwpltx?.seek(offset, duration);
  });

  const seekedHandler = useEventCallback(() => {
    jwpltx?.seeked();
  });

  const completeHandler = useEventCallback(() => {
    jwpltx?.complete();
  });

  const adImpressionHandler = useEventCallback(() => {
    jwpltx?.adImpression();
  });

  return {
    readyHandler,
    timeHandler,
    seekHandler,
    seekedHandler,
    completeHandler,
    adImpressionHandler,
  };
};

export default useOttAnalytics;
